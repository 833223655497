import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Section from "../landingnewcomponents/Section/Landing";
import Title, { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import Layout from "../layouts/layout";
import "./../common/styles.scss";
import { graphql, useStaticQuery } from "gatsby";

const CookiesPage = (props) => {

  const pageData = useStaticQuery(graphql`
      {
          page: wpPage(databaseId: {eq: 2928}) {
              content
              title
              databaseId
              date
              uri
              slug
          }
      }
  `);
  const post = pageData.page;

  return (
    <Layout location={props.location}>
      <Section>
        <Breadcrumbs
          items={[{ title: "Viewst", url: "/" }, { title: post.title, url: "/cookies-policy/" }]}
        />
        <Title color={TITLE_COLORS.Violet}>{post.title}</Title>
        <Section>
          <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
        </Section>
      </Section>
    </Layout>
  );
};

export default CookiesPage;
